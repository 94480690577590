<template>
<footer id="footer" class="relative pt-10">
    <div class="py-0 mx-auto container px-4 flex tems-center">
                <div class="w-full lg:mb-0 flex tems-center">
                    <div class="w-full px-2">
                        <ul>
                            <li class="m-4 p-3" v-for="(link, idx) in products" :key="idx">
                                <a class="text-lg leading-none text-white" :href="link.link">{{link.label}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="w-full px-6">
                        <ul>
                            <li class="m-4 p-3" v-for="(link, idx) in resources" :key="idx">
                                <a class="text-lg leading-none text-white" :href="link.link">{{link.label}}</a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="w-1/2 flex items-start justify-end">
                    <div class="w-full flex justify-between">
                        <div class="flex flex-col md:flex-row items-center bg-theme p-2 rounded-md" >
                            <a href="https://www.facebook.com/quantumfyed" target="_blank" class="my-3 md:my-0">
                              <img src="@/assets/icons/social-media/facebook.svg" class="social-icon"/>
                            </a>
                            <a href="https://www.instagram.com/quantumfyed" target="_blank" class="my-3 md:my-0">
                              <img src="@/assets/icons/social-media/instagram.svg" class="social-icon"/>
                            </a>
                            <a href="https://www.twitter.com/quantumfyed" target="_blank" class="my-3 md:my-0">
                              <img src="@/assets/icons/social-media/twitter.svg" class="social-icon"/>
                            </a>
                        </div>

                    </div>
                </div>
    </div>
    <div class="py-16 flex flex-col justify-center items-center text-center">
        <img src="@/assets/qn-logo-white.svg" class="w-24 brand-logo"/>
        <p class="mt-6 text-lg leading-none text-white">
            Copyright © Quantumfy 2020 - 2023 <br /><br /> All Rights Reserved
        </p>
    </div>
</footer>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            products: [
                {label: 'Learn', link: '/learn'},
                {label: 'Snippets', link: 'https://t.me/quantumfy'}
            ],
            resources: [
                {label: 'About Us', link: '/about'},
                {label: 'FAQ', link: '/faq'},
                {label: 'Contact', link: '/contact'}
            ]
        }
    },
    mounted() {},
    methods: {
    },
};
</script>

<style scoped>
#footer{
    border-top: 3px solid #5ad9d9;
    background-color: #008080;
}
.social-icon{
    width: 2rem;
    margin: 0 1rem;
}
</style>
